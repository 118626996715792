import "bootstrap";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Auth from "./components/Auth/Auth";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ForgotPasswordNewPassword from "./components/Auth/ForgotPasswordNewPassword";
import ForgotPasswordOTP from "./components/Auth/ForgotPasswordOTP";
import Login from "./components/Auth/Login";
import SignUp from "./components/Auth/SignUp";
import DigitalReceipt from "./components/History/DigitalReceipt";
import History from "./components/History/History";
import SingleTransaction from "./components/History/SingleTransaction";
import SingleTransactionRedemption from "./components/History/SingleTransactionRedemption";
import Home from "./components/Home/Home";
import HomeID from "./components/Home/HomeID";
import Offers from "./components/Offer/Offers";
import SingleOffer from "./components/Offer/SingleOffer";
import MyProfile from "./components/Profile/MyProfile";
import Profile from "./components/Profile/Profile";
import Voucher from "./components/Voucher/Voucher";
import VoucherSingle from "./components/Voucher/VoucherSingle";

import About from "./components/About/About";
import SingleAboutItemPage from "./components/About/SingleAboutItemPage";
import Alert from "./components/Auth/Alert/Alert";
import Feedback from "./components/Profile/Feedback";
import SharePoints from "./components/Profile/SharePoints";
import Stores from "./components/Stores/Store";
import TransferVoucher from "./components/Voucher/TransferVoucher";
import { useAppSetting } from "./context/appSettingContext";
import { UserProvider } from "./context/userContext";
import useAlert from "./hooks/Alert";

function App() {
    const location = useLocation();

    const { appSettings, theme, clientAccess } = useAppSetting();

    const { showAlert, alertStyle, alertMessage, hideAlert } = useAlert();

    if ("serviceWorker" in navigator) {
        window.addEventListener("load", () => {
            navigator.serviceWorker
                .register("/custom-service-worker.js")
                .then((registration) => {
                    console.log(
                        "Service Worker registered with scope:",
                        registration.scope
                    );
                })
                .catch((err) => {
                    console.log("Service Worker registration failed:", err);
                });
        });

        // Handle messages from the service worker
        navigator.serviceWorker.addEventListener("message", (event) => {
            console.log(event.data.type);
            if (event.data.type === "CLEAR_STORAGE") {
                // Clear local storage and session storage
                window.localStorage.clear();
                window.sessionStorage.clear();
                console.log("Local and session storage cleared.");
            }
        });
    }

    function emitEvent() {
        console.log("emitted");
        const event = new Event("trueValuePWA");
        window.dispatchEvent(event); // Dispatch the event to trigger the prompt
    }

    if (appSettings !== undefined && clientAccess !== undefined) {
        const baseURL = appSettings.AppBaseURL;
        const AppDisplayName = appSettings?.AppDisplayName;

        const clientID = appSettings.ClientId;

        if (
            baseURL !== undefined &&
            clientID !== undefined &&
            appSettings !== undefined
        ) {
            return (
                <div
                    // onClick={() => emitEvent()}
                    className={
                        "container-fluid w-100 position-relative px-0 font-family-dmsans bg-light h-100 max-width-700 position-relative " +
                        (theme === "dark" ? "dark" : "")
                    }
                >
                    <div
                        className="w-100 text-center position-fixed bg-black top-0 start-0 z-3 d-none"
                        id="promptButton"
                    >
                        <button
                            className="btn btn-primary"
                            onClick={() => emitEvent()}
                        >
                            Install App
                        </button>
                    </div>
                    <AnimatePresence mode="wait">
                        <UserProvider>
                            <Routes key={location.pathname} location={location}>
                                <Route
                                    path="/"
                                    element={
                                        <Auth
                                            emitEvent={() => emitEvent()}
                                            appSettings={appSettings}
                                            AppDisplayName={AppDisplayName}
                                            baseURL={baseURL}
                                            clientID={clientID}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/login"
                                    element={
                                        <Login
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/forgot-password"
                                    element={
                                        <ForgotPassword
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/forgot-password-otp"
                                    element={
                                        <ForgotPasswordOTP
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/forgot-password-new-password"
                                    element={
                                        <ForgotPasswordNewPassword
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/join"
                                    element={
                                        <SignUp
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            appSettings={appSettings}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/home"
                                    element={
                                        <Home
                                            emitEvent={() => emitEvent()}
                                            appSettings={appSettings}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/home/id"
                                    element={
                                        <HomeID
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/offers"
                                    element={
                                        <Offers
                                            emitEvent={() => emitEvent()}
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            appSettings={appSettings}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/offers/single/:promocode"
                                    element={
                                        <SingleOffer
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            appSettings={appSettings}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/history"
                                    element={
                                        <History
                                            emitEvent={() => emitEvent()}
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/transactions/single/:billref"
                                    element={
                                        <SingleTransaction
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/transactions/single/redemption/:billref"
                                    element={
                                        <SingleTransactionRedemption
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/digital-receipt"
                                    element={
                                        <DigitalReceipt
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/profile"
                                    element={
                                        <Profile
                                            emitEvent={() => emitEvent()}
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            appSettings={appSettings}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/my-profile"
                                    element={
                                        <MyProfile
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            appSettings={appSettings}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/vouchers"
                                    element={
                                        <Voucher
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/vouchers/single"
                                    element={
                                        <VoucherSingle
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/vouchers/single/transfer"
                                    element={
                                        <TransferVoucher
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/share-points"
                                    element={
                                        <SharePoints
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/feedback"
                                    element={
                                        <Feedback
                                            baseURL={baseURL}
                                            clientID={clientID}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/stores"
                                    element={
                                        <Stores
                                            appSettings={appSettings}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>

                                <Route
                                    path="/about"
                                    element={
                                        <About
                                            appSettings={appSettings}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>
                                <Route
                                    path="/about/single"
                                    element={
                                        <SingleAboutItemPage
                                            appSettings={appSettings}
                                            AppDisplayName={AppDisplayName}
                                        />
                                    }
                                ></Route>
                            </Routes>
                        </UserProvider>
                    </AnimatePresence>
                </div>
            );
        } else {
            return (
                <div>
                    <Alert
                        showAlert={showAlert}
                        alertStyle={alertStyle}
                        alertMessage={alertMessage}
                        hideAlert={hideAlert}
                    />
                </div>
            );
        }
    } else {
        return "";
    }
}

export default App;
