import React from "react";
import animationgif from "../../images/animation.gif";
import logo from "../../images/logo.png";
import myconlogo from "../../images/mycon-logo.png";

function Splash({ isSplashVisible, appSettings}) {
    const {LogoURL} = appSettings
    return (
        <div
            className={
                "transition-4-ease h-100 w-100 position-absolute z-3 p-0 " +
                isSplashVisible
            }
        >
            <img
                src={animationgif}
                alt="Background animation"
                className="w-100 h-100 position-absolute object-fit-cover"
                style={{
                    filter: `blur(5px)`,
                }}
            />
            <div className="w-100 h-100 bg-wafaa-purple-opacity-50 position-relative align-items-center justify-content-center d-flex">
                <img src={LogoURL} onError={(e) => {e.target.src = logo;}}alt="Logo" />
                <div className="position-absolute bottom-0 text-start text-white">
                    <p>Brought to you by</p>
                    <div className="d-flex">
                        <img
                            src={myconlogo}
                            className="h-100 img-fluid"
                            alt="Mycom logo"
                        />
                        <div className="font-weight-bolder">
                            <p>مايكوم للتقنيات</p>
                            <p>MYCOM Technologies <span className="font-weight-400 font-size-10 text-wafaa-white-1">V1.06</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Splash;
