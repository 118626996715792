import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import brokenImage from "../../images/brokenImage.jpg"; // import the fallback image
import qr_code from "../../images/icons/qr_code.svg";
import download from "../../Utils/download";

function OfferBox({ promotion, imageURL }) {
    // const imageURL = "https://devp.mycomsys.com:8807/files/";
    const [isImgLoading, setIsImgLoading] = useState(true);
    const [isImgLoading2, setIsImgLoading2] = useState(true);
    return (
        <Link
            to={"/offers/single/" + promotion.PROMO_CODE}
            className="text-decoration-none"
        >
            <div className="bg-wafaa-white-2 width-300 overflow-hidden rounded-4 shadow-wafaa mx-3 min-h-card d-flex flex-grow-1 flex-column justify-content-between">
                <div>
                    <div className="w-100 position-relative">
                        <img
                            style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "fill",
                                objectPosition: "center",
                                filter: isImgLoading ? "blur(10px)" : "none",
                                transition: "filter 0.3s ease-out",
                            }}
                            loading="lazy"
                            src={imageURL + promotion.PROMO_IMAGE}
                            alt="Offer"
                            className="w-100 height-225 object-fit-cover"
                            onError={(e) => {
                                e.target.src = brokenImage;
                                setIsImgLoading(false);
                            }}
                            onLoad={() => setIsImgLoading(false)} // fallback to broken image for the main image
                        />
                        {/* <img
                            style={{
                                filter: isImgLoading2 ? "blur(10px)" : "none",
                                transition: "filter 0.3s ease-out",
                            }}
                            loading="lazy"
                            src={imageURL  + promotion.promo_flyer}
                            alt="Logo"
                            className="position-absolute bottom-10 right-10 object-fit-cover height-50"
                            onError={(e) => {
                                e.target.src = brokenImage;
                                setIsImgLoading2(false);
                            }}
                            onLoad={() => setIsImgLoading2(false)}
                        /> */}
                    </div>
                    <span className="badge bg-wafaa-purple mt-n3 ms-2 d-block  position-relative w-35 px-3 py-2 font-size-10">
                        Bonus Offer
                    </span>
                </div>
                <div>
                    <p className="font-size-12 w-70 ms-2 mt-2 mb-0 text-wafaa-black-1">
                        {promotion.PROMO_DESC}
                    </p>
                    <p className="font-size-12 w-full ms-2 mt-2 mb-2 text-wafaa-purple ">
                        {promotion?.DESC?.length > 132
                            ? promotion.DESC?.slice(0, 132) + "..."
                            : promotion.DESC}
                    </p>

                    <div className="px-2 pb-2 d-flex justify-content-between w-100 align-items-center">
                        <img
                            src={qr_code}
                            alt="QR Code"
                            className="height-30 width-30"
                        />
                        <span className="font-size-10 text-wafaa-grey">
                            Valid until
                        </span>
                        <span className="font-size-10 text-wafaa-black-1">
                            {moment(promotion.VALID_TO).format("DD/MM/YYYY")}
                        </span>
                        <button
                            // onClick={async () => {
                            //     await download(
                            //         promotion?.FlyerPath,
                            //         promotion?.FlyerPath.split("/")[
                            //             promotion?.FlyerPath.split("/").length -
                            //                 1
                            //         ]
                            //     );
                            // }}
                            onClick={async () => {
                                await download(
                                    promotion
                                        ? imageURL + promotion.promo_flyer
                                        : "", "flyer image"
                                );
                            }}
                            className="bg-wafaa-purple btn btn-white shadow-wafaa-btn text-white"
                        >
                            <svg
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 16.077L8.461 12.539L9.169 11.819L11.5 14.15V5.5H12.5V14.15L14.83 11.82L15.539 12.539L12 16.077ZM6.616 19.5C6.15533 19.5 5.771 19.346 5.463 19.038C5.155 18.73 5.00067 18.3453 5 17.884V15.461H6V17.884C6 18.038 6.064 18.1793 6.192 18.308C6.32 18.4367 6.461 18.5007 6.615 18.5H17.385C17.5383 18.5 17.6793 18.436 17.808 18.308C17.9367 18.18 18.0007 18.0387 18 17.884V15.461H19V17.884C19 18.3447 18.846 18.729 18.538 19.037C18.23 19.345 17.8453 19.4993 17.384 19.5H6.616Z"
                                    fill="white"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default OfferBox;
