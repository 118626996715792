import { useState, useEffect } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import PageTitle from '../Misc/PageTitle';
import SingleItem from './SingleItem';
import SingleItemSkeleton from './SingleItemSkeleton'

function About({ appSettings }) {

    const [sections, setSections] = useState([]);
    const [loadingAbout, setLoadingAbout] = useState(false);

    const { ClientId: clientID, AppBaseURL1, AppDisplayName} = appSettings;


    useEffect(() => {
        loadStores();
    }, []);

    async function loadStores() {
        // Start loading
        setLoadingAbout(true);
    
        const payLoad = {
            "DATA": {
                "client_id": clientID
            }
        };
    
        try {
            const request = await fetch(`${AppBaseURL1}Loy_getAboutUs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payLoad)
            });
    
            const response = await request.json();
    
            if (request.ok) {
                setSections(response.DATA);
            }
        } catch (error) {
            console.error("Error loading stores:", error);
            // Handle the error (optional)
        } finally {
            // Stop loading
            setLoadingAbout(false);
        }
    }
    

    return (
        <AnimatedPage>
            <div className='position-sticky z-2'>
                <PageTitle name={`About ${AppDisplayName ? AppDisplayName : "True Value"}`} />
            </div>


            <div className='w-100 h-85 overflow-y-scroll pb-5 pt-3 transition-0-5-bounce bg-wafaa-white-3'>
                {
                    loadingAbout ? 
                    Array(2).fill(0).map((_,idx) => <SingleItemSkeleton/>)
                    :
                    sections.map((section, index) => (
                        <SingleItem section={section} key={index} />
                    ))
                    
                }
            </div>

        </AnimatedPage>
    );
}

export default About;
