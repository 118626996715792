import { useEffect, useRef, useState } from "react";

function AreaModal({
    setCustomerData,
    customerData,
    modalID,
    baseURL,
    appSettings,
}) {
    const closeButton = useRef();

    const [areas, setAreas] = useState([]);

    const [areaCode, setAreaCode] = useState(customerData.AREA_CODE);
    const [getArea, setGetArea] = useState("");
    useEffect(() => {
        getAreas();
    }, [customerData]);

    async function getAreas() {
        if (customerData.COUNTRY_CODE === "" || customerData.CITY_CODE === "")
            return;

        const payLoad = {
            DATA: {
                client_id: 80623,
                data_type: "M_AREA",
                country_code: customerData.COUNTRY_CODE,
                city_code: customerData.CITY_CODE,
            },
        };

        try {
            const request = await fetch(
                `${appSettings.AppBaseURL1}CRM_DownloadCou_Ci_Ar`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payLoad),
                }
            );

            const response = await request.json();

            setAreas(response.DATA);
        } catch (error) {
            console.error(error);
            alert("Cannot get areas");
        }
    }

    async function update(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        const payLoad = {
            DIV_DES: "",
            DIV_ID: "1",
            FUNCTION: "LoyUpdateCustomer",
            SEND_KEY: "123456",
            DATA: {
                AUTH_KEY: "TXlDb206TG95QVBJMTIz",
                TYPE_NAME: "CUSTOMER_UPDATE",
                CUSTOMER_DETAILS: {
                    CUST_CODE: customerData.CUST_CODE,
                    FIRSTNAME: customerData.CUST_NAME.split(" ")[0],
                    LASTNAME: customerData.CUST_NAME.split(" ")[1],
                    MOBILE: customerData.MOBILE,
                    EMAIL: customerData.EMAIL,
                    ADDR1: customerData.ADDR1,
                    ADDR2: customerData.ADDR2,
                    ADDR3: customerData.ADDR3,
                    BIRTHDATE: customerData.BIRTH_DATE,
                    COMPNAME: customerData.COMP_NAME,
                    NATIONALITY: customerData.NATIONALITY,
                    GENDER: customerData.GENDER,
                    country_code: customerData.country_code,
                    city_code: customerData.city_code,
                    area_code: areaCode,
                    CITY: customerData.CITY,
                    // "AREA": formData.get('area'),
                    AREA: getArea,
                    COUNTRY: customerData.COUNTRY,
                },
            },
        };
        const request = await fetch(`${baseURL}/LoyUpdateCustomer`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payLoad),
        });

        const response = await request.json();
        if (response.STATUS) {
            customerData.AREA = getArea;
            customerData.AREA_CODE = areaCode;
            setCustomerData({ ...customerData });
            closeButton.current.click();
        } else {
            closeButton.current.click();
            alert("Failed to update");
        }
    }

    return (
        <>
            <div
                className="modal fade max-width-700 start-50"
                style={{
                    transform: "translateX(-50%)",
                }}
                id={modalID}
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered modal-fullscreen max-width-700">
                    <div className="modal-content">
                        <div className="modal-header shadow bg-wafaa-white-2">
                            <h5 className="modal-title text-wafaa-black-1">
                                Change Country
                            </h5>
                            <button
                                type="button"
                                className="text-wafaa-black-1 bg-transparent border-0"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{
                                    transform: "scale(1.8)",
                                }}
                                ref={closeButton}
                            >
                                &times;
                            </button>
                        </div>
                        <form
                            onSubmit={(event) => {
                                update(event);
                            }}
                            className="h-100 d-flex flex-column"
                        >
                            <div className="h-80 overflow-y-scroll pb-4 bg-wafaa-white-2">
                                {areas.length > 0 ? (
                                    areas.map((area, index) => (
                                        <label
                                            key={area.area_name}
                                            className="form-check-label p-2 d-flex justify-content-between border-bottom text-wafaa-black-1"
                                        >
                                            <div>{area.area_name}</div>
                                            {area.city_name ===
                                            customerData.CITY ? (
                                                <input
                                                    onClick={() =>
                                                        setGetArea(
                                                            area.area_name
                                                        )
                                                    }
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="city"
                                                    defaultValue={
                                                        area.area_name
                                                    }
                                                    defaultChecked
                                                />
                                            ) : (
                                                <input
                                                    onClick={() =>
                                                        setGetArea(
                                                            area.area_name
                                                        )
                                                    }
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="city"
                                                    defaultValue={
                                                        area.area_name
                                                    }
                                                />
                                            )}
                                        </label>
                                    ))
                                ) : (
                                    <div className="text-center text-wafaa-black-1 mb-0">
                                        No areas or no city chosen
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer bg-wafaa-white-2">
                                <button className="w-100 btn btn-lg py-2-5 btn-primary bg-wafaa-purple-2 border-wafaa-purple shadow-wafaa-btn font-size-12">
                                    DONE
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AreaModal;
